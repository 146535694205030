<template>
    <el-table border :data="tableData" tooltip-effect="dark" empty-text="请上传文件" height="string">
        <template  name="tableColumn">
            <el-table-column prop="attachmentName" label="文件名称" align="center" />
            <el-table-column prop="attachmentType" label="文件类型" align="center" />

            <el-table-column prop="beginDate" label="合同起始时间" align="center">
                <template slot-scope="scope">
                    <el-date-picker value-format="timestamp"  v-model="scope.row.beginDate" :editable="false" type="date" placeholder="请选择起始时间" @keyup.enter.native="submit"></el-date-picker>
                </template>
            </el-table-column>
            <el-table-column prop="endDate" label="合同终止时间" align="center">
                <template slot-scope="scope">
                    <el-date-picker value-format="timestamp"  v-model="scope.row.endDate" :editable="false" type="date" placeholder="请选择终止时间" @keyup.enter.native="submit"></el-date-picker>
                </template>
            </el-table-column>
            <el-table-column prop="endDateTwo" label="合同终止时间2" align="center">
                <template slot-scope="scope">
                    <el-date-picker value-format="timestamp"  v-model="scope.row.endDateTwo" :editable="false" type="date" placeholder="请选择终止时间2" @keyup.enter.native="submit"></el-date-picker>
                </template>
            </el-table-column>


            <el-table-column prop="attachmentPath" label="文件路径" align="center">
                <template slot-scope="scope">
                    <template v-if="scope.row.loading">
                        <i class="el-icon-loading" style="color:#f00;"></i>正在上传
                    </template>
                    <template v-else>
                        <a target="_blank" style="text-decoration:revert;color:#409eff" :href="`${filepath}${scope.row.attachmentPath}`">{{  scope.row.attachmentPath  }}</a>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center">
                <template slot-scope="scope">
                    <el-input
                            type="textarea"
                            v-model="scope.row.remark"
                            :autosize="{ minRows: 2, maxRows: 2 }">
                    </el-input>
                </template>
            </el-table-column>
        </template>
        <el-table-column align="center" width="100">
            <template slot="header" slot-scope="scope">
                <Upload :fileCodeParam="fileCode" v-if="!disabled" @upload="upload"></Upload>
            </template>
            <template slot-scope="scope">
                <el-button type="text" size="small" v-if="!disabled" @click="del(scope)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
export default {
    components: {
        Upload: () => import("./AnUpload.vue"),
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            // type: Array,
            default: function () {
                return []
            }
        },
        fileCode:{
            type:String,
            default:'default',
        },
    },
    data() {
        return {
            filepath:process.env.VUE_APP_FILEPATH,
            tableData:[],
            parms:{},

        }
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    watch: {
        value:{
            immediate:true,//初始化立即执行
            handler: function (newVal){
                this.tableData = newVal;
            }
        },
    },
    mounted() {},
    methods: {
        upload(r){
            r.some(res=>{
                this.tableData.push(res.combination({
                    fileCode:this.fileCode,
                }));
            });
            // console.log(this.tableData)

            this.$emit('change', this.tableData);
        },
        del(scope){
            if(scope.row.id){
                this.$api.user.deleteUserAttachment(scope.row.id).then(res => {
                    this.tableData.splice(scope.$index,1);
                    this.$emit('change', this.tableData);
                }).catch(err => {});
            }else {
                this.tableData.splice(scope.$index,1);
                this.$emit('change', this.tableData);
            };
        },
    }
};
</script>
<style lang="scss" scoped>
</style>
